<template>
  <div>
    <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>内容管理</el-breadcrumb-item>
      <el-breadcrumb-item>标签管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="add">
      <el-button @click="add('new', '')" size="small" type="warning">添加</el-button>
      <el-input
        size="small"
        placeholder="请输入标签名"
        clearable
        prefix-icon="el-icon-search"
        v-model="searchtag"
        style="width: 220px; margin:0 10px;"
        @clear="clearsearch"
      >
      </el-input>
      <el-button @click="searchTag()" type="primary" size="small"
        >搜索</el-button
      >
    </div>
    <el-table :data="list" border style="width: 100%">
      <el-table-column label="编号" prop="id" width="80"></el-table-column>
      <el-table-column prop="id" v-if="false"></el-table-column>
      <el-table-column prop="tag" label="标签"></el-table-column>
      <el-table-column>
        <template slot-scope="scope">
          <div class="opt">
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-edit"
              @click="add('bj', scope.row)"
              >修改
            </el-button>
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="del(scope.row, scope.$index)"
            >
              删除
            </el-button>
            <el-button
              size="mini"
              type="warning"
              icon="el-icon-edit"
              @click="addkeyword(scope.row)"
              >添加关键字
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 添加标签 -->
    <el-dialog :title="title" :visible.sync="dialogFormVisible">
      <el-form :model="form" class="hdtc" label-width="170px">
        <el-form-item label="请输入标签名：">
          <el-input
            style="float: left"
            v-model="form.tag"
            autocomplete="off"
            placeholder="请输入标签名"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="选择分组：" style="text-align: left">
          <el-select v-model="group" placeholder="请选择分组">
            <el-option
              v-for="item in groupoptions"
              :key="item.id"
              :label="item.label"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择疾病：" style="text-align: left">
          <el-select
            v-model="form.ill_lib_id"
            filterable
            placeholder="请选择疾病"
          >
            <el-option
              v-for="item in illlib"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          @click="
            dialogFormVisible = false;
            form = {};
          "
          >取 消</el-button
        >
        <el-button type="primary" @click="sub">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 添加关键字 -->
    <el-dialog title="关键字" :visible.sync="keyword">
      <div style="text-align: left; overflow: hidden">
        <p>现有关键词：</p>
        <el-tag
          v-for="tag in tags"
          :key="tag.id"
          closable
          @close="delkeyword(tag)"
          disable-transitions
          style="margin-right: 10px; float: left; margin-bottom: 8px"
        >
          {{ tag.des }}
        </el-tag>
      </div>
      <el-divider></el-divider>
      <div style="text-align: left; overflow: hidden">
        <p>
          请输入关键词：
          <el-input
            type="text"
            v-model="keyworddes"
            style="width: 20%"
          ></el-input>
        </p>
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button @click="keyword = false">取 消</el-button>
        <el-button type="primary" @click="subkeyword">保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  inject: ["reload"],
  data() {
    return {
      list: [],
      current: 1, //默认显示第几页
      total: 0, // 总条数，根据接口获取数据长度
      pageSizes: [20], // 个数选择器（可修改）
      PageSize: 20, // 默认每页显示的条数（可修改）
      value: false,
      form: {
        tag: "",
        ill_lib_id: "",
      },
      dialogFormVisible: false,
      title: "添加标签",
      type: "",
      search: "",
      group: "",
      groupoptions: [{ id: 1, label: "第一组" }],
      keyword: false,
      tags: [],
      keyworddes: "",
      keywordrow: "",
      illlib: [],
      searchtag: "", //搜索疾病标签
    };
  },
  mounted() {
    this.getdata();
    this.getilllib();
  },
  methods: {
    getdata() {
      this.axios
        .get("/tag/get_gu_tag")
        .then((rs) => {
          if (rs.data.code == 0) {
            this.list = rs.data.result;
          } else {
            console.log(rs);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getilllib() {
      this.axios.get("/gu/get_gu_ill_lib").then((rs) => {
        if (rs.data.code == 0) {
          this.illlib = rs.data.result;
        }
      });
    },
    clearsearch() {
      this.searchtag = "";
      this.getdata();
    },
    searchTag() {
      this.axios.get('/tag/get_gu_tag?keyword='+this.searchtag).then((res) => {
        if (res.data.code == 0) {
          this.list = res.data.result;
        } else {
          this.list = [];
        }
      });
    },
    add(type, item) {
      this.type = type;
      this.item = item;
      this.dialogFormVisible = true;
      if (item == "") {
        this.id = "添加标签";
        this.form.tag = "";
      }
      if (type != "new") {
        this.id = "修改标签";
        this.form.tag = item.tag;
        this.form.ill_lib_id = item.ill_lib_id;
      } else {
        this.id = "添加标签";
        this.form.tag = "";
        this.form.ill_lib_id = "";
      }
    },
    del(row, index) {
      this.$confirm("此操作将永久删除该标签, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          console.log();
          this.axios
            .post(
              "/tag/delete_gu_tag",
              this.qs.stringify({ id: row.id, tag: row.tag })
            )
            .then((rs) => {
              if (rs.data.code == 0) {
                this.$message({
                  message: "删除成功",
                  type: "success",
                });
                this.list.splice(index, 1);
              } else {
                this.$message(rs.data.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    sub() {
      if (this.form.tag == "") {
        this.$message({
          type: "error",
          message: "请填写标签名",
        });
        return;
      }
      if (this.form.ill_lib_id == "") {
        this.$message({
          type: "error",
          message: "请选择疾病",
        });
        return;
      }
      let url = "";
      let str = "";
      if (this.type == "new") {
        url = "/tag/insert_gu_tag";
        str = "添加成功";
      } else {
        url = "/tag/update_gu_tag";
        str = "修改成功";
        this.form.id = this.item.id;
      }
      console.log(this.form);
      this.axios
        .post(url, this.qs.stringify(this.form))
        .then((rs) => {
          console.log(rs);
          if (rs.data.code == 0) {
            this.dialogFormVisible = false;
            this.form = {};
            this.$message({
              message: str,
              type: "success",
            });
            this.reload();
          } else {
            this.$message(rs.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addkeyword(row) {
      this.keyword = true;
      this.keywordrow = row;
      this.tags = [];
      this.keyworddes = "";
      this.axios
        .get("/tag/get_gu_tag_des_by_tagid?tag_id=" + row.id)
        .then((res) => {
          if (res.data.code == 0) {
            this.tags = res.data.result;
          }
        });
    },
    subkeyword() {
      var form = {
        des: this.keyworddes,
        tag_id: this.keywordrow.id,
      };
      console.log(form);
      this.axios
        .post("/tag/insert_gu_tag_des", this.qs.stringify(form))
        .then((res) => {
          if (res.data.code == 0) {
            this.tags.push(form);
            this.$message({
              message: "保存成功",
              type: "success",
            });
            this.keyworddes = "";
            // this.keyword = false;
          } else {
            this.$message({
              message: res.data.msg,
              type: "success",
            });
          }
        });
    },
    delkeyword(val) {
      console.log(val);
      this.axios
        .post("/tag/delete_gu_tag_des", this.qs.stringify({ id: val.id }))
        .then((res) => {
          if (res.data.code == 0) {
            this.tags.splice(this.tags.indexOf(val), 1);
            this.$message({
              message: "删除成功",
              type: "success",
            });
          }
        });
    },
  },
};
</script>

<style>
.opt a {
  cursor: pointer;
  color: green;
  margin-right: 20px;
  display: inline-block;
}

.add {
  width: 100%;
  height: 50px;
  text-align: left;
}

.hdtc {
  width: 80%;
}

.Bdh {
  height: 50px;
  width: 100%;
  line-height: 40px;
}
</style>
